import { Injectable } from "@angular/core";
import { rc_PopOverlayRef } from "./rc_popover-ref";
import { rc_popoverService } from "./rc_popover.service";



@Injectable()
export class rc_utilsService {

    constructor(private _popoverService: rc_popoverService){

    }

    openPopUp(){
        let component = 'homeComponent';
        let dialogRef: rc_PopOverlayRef = this._popoverService.open({},component );
    }
    
}