//__IMPORT_MODULES_HERE__
import {NgModule } from '@angular/core';
import { sdProviders} from './sd-providers';
import { environment } from 'environments/environment';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
@NgModule({
  imports: [
    //__CUSTOM_IMPORTS_MODULES__
    //__CUSTOM_IMPORTS_MODULES_END__
    //__IMPORTS_MODULES__
    //__IMPORTS_MODULES_END__
    CKEditorModule
  ],
  providers: [...sdProviders],
  exports: [
    //__EXPORTS_MODULES__
    //__EXPORTS_MODULES_END__
    CKEditorModule
  ]
})
export class DependenciesModule { }