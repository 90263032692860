/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';
//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as Editor from '../../../ckeditor';
import { rc_PopOverlayRef } from '../../services/rc_popover/rc_popover-ref';
import { rc_popoverService } from '../../services/rc_popover/rc_popover.service';
/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-home',
    templateUrl: './home.template.html'
})

export class homeComponent extends NBaseComponent implements OnInit {

Editor = Editor;
sampleForm: FormGroup;
htmlContent:any;
message:string = '';
showEditor: boolean = false;


    constructor(private formBuilder: FormBuilder,private _popoverService:rc_popoverService) {
        super();
    }
    ngOnInit() {
        this._intializeForm();
    }

  public  _intializeForm() {
      this.sampleForm = this.formBuilder.group({
          editor : ['', Validators.required]
      })
    }

    public patchValue() {
        this.sampleForm.patchValue({
            editor:'patching the value'
        })
    }

    public getEditorValue() {
        let data = {
            editorValue : this.sampleForm.value.editor,
            editor1: this.htmlContent
        }
        console.log(data);
    }

    public removeHTML(str){ 
        let tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        let txtWithoutHtml = tmp.textContent || tmp.innerText || ""
        tmp.remove()
        return txtWithoutHtml;
    }

    openPopUp(){
        let component = 'homeComponent';
        let dialogRef: rc_PopOverlayRef = this._popoverService.open({},component );
    }
}
