/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE CLASS NAME*/
import { Injectable, Inject, OnInit, Injector, ComponentRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { rc_PopOverlayRef } from './rc_popover-ref';
import { homeComponent } from '../../components/homeComponent/home.component';

interface PopOverDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?:any;
  showPolicyTemplate?:Boolean
}

const DEFAULT_CONFIG: PopOverDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'popover-backdrop',
  panelClass: 'rc_popover-panelclass',
}

@Injectable()
export class rc_popoverService {

  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  open(config: PopOverDialogConfig , component ) {
    console.log('checking')
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new rc_PopOverlayRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef, component);

    overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: PopOverDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    overlayConfig['data'] = config['data'];
    overlayConfig['showPolicyTemplate'] = config['showPolicyTemplate']
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: PopOverDialogConfig, dialogRef: rc_PopOverlayRef, component: string) {
    const injector = this.createInjector(config, dialogRef);
      const containerPortal = new ComponentPortal(homeComponent, null, injector);
      const containerRef: ComponentRef<homeComponent> = overlayRef.attach(containerPortal);
      return containerRef.instance;
    
  }

  private createInjector(config: PopOverDialogConfig, dialogRef: rc_PopOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(rc_PopOverlayRef, dialogRef);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: PopOverDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }

}

