/*DEFAULT GENERATED TEMPLATE. DO NOT CHANGE SELECTOR TEMPLATE_URL AND CLASS NAME*/
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { NBaseComponent } from '../../../../../app/baseClasses/nBase.component';

/*
Client Service import Example:
import { servicename } from 'app/sd-services/servicename';
*/

/*
Legacy Service import Example :
import { HeroService } from '../../services/hero/hero.service';
*/

@Component({
    selector: 'bh-rich',
    templateUrl: './rich.template.html'
})

export class richComponent extends NBaseComponent implements OnInit {

    constructor(private router: Router) {
        super();
    }

    ngOnInit() {

    }

    navigate() {
      // this.utils.openPopUp();
      this.router.navigate(['/editor'])
    }
}
